<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >

    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="12"
      md="9"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <div class="d-flex content-left">
            <h2 class="content-header-title float-left pr-1 mb-0">
              {{ pageTitle }}
            </h2>
            <CompanyThumb
              v-if="company"
              :logo="company.logo"
              :name="company.name"
              wide
              class="ml-1"
            />
          </div>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="fixToUrl(item.to)"
              >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

  </b-row>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import CompanyThumb from '@/views/components/CompanyThumb.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    CompanyThumb,
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
  },
  data() {
    return {
      pageTitle: this.$route.meta.pageTitle,
      company: this.$route.meta.company,
    }
  },
  watch: {
    $route: {
      handler: function (change) {
        this.updateTitle(change.meta.pageTitle)
        this.updateCompany(change.meta.company)
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.$globalEvent.$on('update-page-title', this.updateTitle)
    this.$globalEvent.$on('update-page-company', this.updateCompany)
  },
  methods: {
    fixToUrl(url) {
      if (url) {
        const path = window.location.pathname.split('/')
        if (path.length >= 3 && path[1] === 'shipments') {
          url = url.replace(':shipmentId', path[2])
        }
      }
      return url
    },
    updateTitle(newTitle) {
      this.pageTitle = newTitle
    },
    updateCompany(logo) {
      this.company = logo
    },
  },
}
</script>
