<template>
  <div>
    <b-img
      v-if="logo"
      :src="logo"
      fluid
      rounded
      width="30px"
      :alt="name"
    />
    <div
      v-else
      :class="!wide ? 'text-truncate' : 'wide-text-logo'"
      :title="name"
    >
      <b-icon-briefcase />
      {{ name }}
    </div>
  </div>
</template>
<script>
import {
  BImg,
  BIconBriefcase,
} from 'bootstrap-vue'

export default {
  name: 'CompanyThumb',
  components: {
    BImg,
    BIconBriefcase,
  },
  props: {
    logo: { type: String, default: '' },
    name: { type: String, default: '' },
    wide: { type: Boolean, default: false },
  },
  data() {
    return {}
  },
}
</script>
<style scoped>
  .text-truncate {
    max-width: 65px;
  }
  .wide-text-logo {
    margin-top: 4px;
  }
</style>
