<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <div
      v-if="isVerticalMenuCollapsed"
      class="logo-overlap"
    />
    <!-- main menu header-->
    <div
      class="navbar-header expanded"
    >
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row d-flex justify-content-between">

          <!-- Logo & Text -->
          <li class="nav-item mx-left">
            <b-link
              class="navbar-brand"
              to="/"
            >
              <span
                class="brand-logo"
              >
                <b-img
                  :src="appLogoImageWhite"
                  alt="logo"
                />
              </span>
            </b-link>
          </li>
          <li class="nav-item nav-toggle">
            <b-button
              variant="outline-light"
              @click="toggleCollapsed"
            >
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="16"
              />
            </b-button>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <vertical-nav-menu-items
        :items="calculatedNavItems"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg, BButton } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import navMenuItems from '@/navigation/vertical'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
    BButton,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  computed: {
    calculatedNavItems() {
      const items = []
      const permissionsObject = this.$permissions().data.permissions
      this.navMenuItems.forEach(item => {
        if (item && item.permissions) {
          if (
            (item.permissions.companyUser && this.$permissions().hasAny(permissionsObject.company_user, item.permissions.companyUser))
            || (item.permissions.user && this.$permissions().hasAny(permissionsObject.user, item.permissions.user))
          ) {
            items.push(item)
          } else if (!item.permissions.companyUser && !item.permissions.user) {
            items.push(item)
          }
        } else if (item.conditions) {
          let result = true
          item.conditions.forEach(condition => {
            // Enter conditions here
            if (condition === 'hasCallbacks') {
              if (!this.$activeCompany().data.company.callbacks) {
                result = false
              }
            }
          })
          // If all conditions matched
          if (result === true) {
            items.push(item)
          }
        } else {
          items.push(item)
        }
      })
      return items
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'ChevronRightIcon' : 'ChevronLeftIcon'))

    // App Name
    const { appName, appLogoImageWhite, appLogoImageIcon } = $themeConfig.app

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImageWhite,
      appLogoImageIcon,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
.nav-item.nav-toggle {
  padding-top: 20px;
  z-index: 2;

  .btn {
    padding: 3px;
    border-radius: 50%;
  }
}
.logo-overlap {
  position: absolute;
  top: 0;
  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(40,48,70,1) 15%);
  width: 24px;
  height: 60px;
  z-index: 1;
  right: 0;
}
.semi-dark-layout .menu-collapsed .main-menu .navigation-main li.active > a {
  background: #161d31 !important;
}
</style>
